
export default function Privacidade() {

    return(
<>
<br/>
<div class="container">
    <div class="row">
    
        <div class="col col-md-12" style={{textAlign:'justify', color:'black', backgroundColor:'white'}}>
            
<h1 style={{textAlign:'center'}}>Política de Privacidade</h1>
<ul>
<li>A <b>Albergauto,lda</b> (Responsável pelo Tratamento de Dados) promove o tratamento de dados pessoais do cliente pessoa singular, 
dando cumprimento às normas e princípios estabelecidos pelo Regulamento Geral de Protecção de Dados (RGPD).</li>
<li>O tratamento de dados pessoais tem por justificação o consentimento prestado pelo titular dos dados pessoais, 
    a negociação, celebração e execução de contratos, o cumprimento de obrigações jurídicas e os interesses legitimamente prosseguidos 
    pela <b>Albergauto,lda</b>. O fundamento jurídico do tratamento é o previsto na norma do art 6º, nº 1, als. a), b), c) e f) do RGPD. 
    Os interesses legítimos invocados reportam-se ao comércio, divulgação e marketing desenvolvidos pela <b>Albergauto,lda</b> 
    no exercício da sua atividade comercial.</li>
<li>Os dados pessoais tratados respeitam ao nome, idade, contacto telefónico, endereço de correio electrónico, 
    número de identificação fiscal morada. Se disponibilizados por via electrónica, 
    os dados tratados podem incluir o registo de acessos ao site e dados de acesso a área reservada como username e password, 
    e se enviados pelo nosso site, são encriptados e protegidos por mecanismos de segurança avançada e 
    destinar-se-ão á permitir a gestão do site, da área de acesso reservado, desenvolvimento e melhoria dos serviços 
    e produtos disponibilizados ao Cliente.
    No contacto por via telefónica a <b>Albergauto,lda</b> pode proceder à gravação das chamadas, 
    para fim de prova de transacções comerciais e de outras comunicações no âmbito de relação pré-contratual ou contratual, 
    de assistência técnica e gestão da qualidade de serviço. Os dados de voz assim recolhidos serão eliminados 
    uma vez verificada a frustração da negociação ou decorrido o prazo de dois anos após a prestação do serviço ou 
    entrega do produto comercializado.</li>
<li>A finalidade da recolha e tratamento de dados tem por fim o desenvolvimento e a divulgação de marketing, 
    produtos e serviços da <b>Albergauto,lda</b>, incluindo mailing list, newsletters, a gestão, acesso, registo e organização do site, 
    a gestão de contrato, apoio a cliente, assistência, cumprimento de obrigações jurídicas, gestão de contencioso, 
    e segurança de dados. Os dados pessoais recolhidos serão conservados durante o tempo pelo qual a <b>Albergauto,lda</b> 
    disponibilize os seus serviços e produtos ao Cliente, podendo manter tal registo por período posterior à cessação 
    de tal condição até ao limite do prazo máximo de prescrição de obrigações contratuais, que é actualmente de vinte anos, 
    findo o qual serão eliminados, sem prejuízo do antes estabelecido relativamente à conservação dos dados de gravação de voz.</li>
<li>Os dados recolhidos devem ser exactos e atuais, são destinados à entidade responsável pelo tratamento de dados, 
    e não serão transmitidos a terceiros sem autorização prévia do seu titular. 
    Tal não impede de possam ser partilhados sem este consentimento para o cumprimento de obrigações jurídicas, 
    designadamente perante a Autoridade Tributária e Aduaneira, Instituto Nacional de Estatística, ASAE, 
    autoridades policiais ou judiciais, e demais entidades perante as quais a <b>Albergauto,lda</b> tenha obrigação 
    que importe a transmissão dos dados pessoais do Cliente, no limite do necessário à finalidade de tal obrigação.</li>
<li>O consentimento conferido pelo utilizador pode ser retirado mediante comunicação escrita dirigida à <b>Albergauto,lda</b>, 
    entidade responsável pelo tratamento de dados, <br/>para o endereço electrónico <a href="mailto:albergauto@gmail.com">albergauto@gmail.com</a>. A revogação total ou parcial do consentimento 
    não comprometerá a licitude do tratamento de dados anterior, nem a necessidade de preservação 
    do tratamento que se revele necessária ao cumprimento das finalidades previstas na norma do 
    art. 6º, nº 1, als. b) e c) do RGPD. O tratamento dos dados pessoais, aqui previsto, 
    constitui requisito para acesso aos serviços e produtos comercializados pela <b>Albergauto,lda</b>, 
    às funcionalidades do site, 
    à gestão, preparação, cumprimento de encomendas e de contratos, ao cumprimento de obrigações jurídicas, 
    e a sua falta importa a inviabilidade de utilização da área reservada do site pelo titular dos dados pessoais, 
    de recepção de comunicações da <b>Albergauto,lda</b> e a limitação na prestação dos serviços que pressuponham 
    o tratamento de dados pessoais.</li>
<li>Ao titular dos dados pessoais assiste o direito de acesso, rectificação, apagamento e à limitação e oposição 
    do tratamento dos seus dados pessoais, bem como à portabilidade dos dados pessoais que tenha fornecido 
    que assentem no consentimento e quando o tratamento seja automatizado.</li>
<li>O titular dos dados pessoais tem direito a apresentar reclamação junto de autoridade 
    e de controlo, que em Portugal é a Comissão Nacional de Protecção de Dados, 
    com instalações na Rua de S. Bento, 148, r/C, 1200-821 Lisboa.</li>
<li>A presente comunicação dá cumprimento ao dever de informação previsto na norma do art. 13º do RGPD.</li>
<li>A relação com a <b>Albergauto,lda</b> pressupõe a aceitação pelo Cliente dos termos e condições 
    da Política de Privacidade expressa neste documento.</li>
</ul>

</div>
</div>
</div>
</>
)
}

