import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Contacto() {

    return(
        
        <Container>	
            <br />		
			<Row>	
                <Col lg={7} >
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8232.284239590439!2d-8.496506235160712!3d40.705780080817455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd239e18b319a00d%3A0xf1498cf0175fc3fb!2sArruamento+D%2C+3850+Albergaria-a-Velha!5e0!3m2!1spt-PT!2spt!4v1453333083519" width="100%" height="400" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                </Col>
                    
                <Col lg={4} >
                <h2>Contactos</h2>
                <p>
                Rua D, Lote 45 - Apartado 26<br/> 
				Zona Industrial <br/>
				3854 - 909 Albergaria-a-Velha<br/><br/>

				Tel. : (+351) 234 522 604<br/>
				Fax : (+351) 234 521 047<br/>
				Email : <a href="mailto:albergauto@gmail.com">albergauto@gmail.com </a>
                </p>
                <a href='https://www.livroreclamacoes.pt/Inicio/' style={{fontSize:'7pt'}}>Livro Reclamações</a>
                
                </Col>
            </Row>
            </Container>
        
        
        
        
    );
}